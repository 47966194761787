<template>
  <div class="grid min-h-screen grid-rows-[min-content,auto,min-content]">
    <div class="flex min-h-screen flex-col px-6 py-12 sm:justify-center sm:bg-indigo-50">
      <div class="mx-auto flex w-full flex-col gap-8 rounded-3xl bg-white sm:w-[32rem] sm:px-16 sm:py-20 sm:shadow-lg">
        <img
          class="mx-auto"
          src="~/assets/images/logo-dark.svg"
        >
        <div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
